<template>
  <app-container
    icon="account_circle"
    colWidth="col-11 col-lg-10"
    v-if="!isTextLoading"
  >
    <template #header>
      <h1 class="mb-4 text-dark">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="register">
        <app-form
          :form="form"
          :model="model"
          :rules="rules"
          form_type="register"
          :button="texts.submit_button"
          @submitted="handleSubmit"
        >
        </app-form>
      </div>
    </template>
  </app-container>
</template>

<script>
/**
 * TODO: Fix error message
 */

import Database from '@/utils/services/Database';
import Utilities from '@/utils/services/Utilities';
import { form, model, rules } from '@/config/forms/auth/register';
import useTexts from '@/mixins/useTexts';

export default {
  name: 'RegisterPage',
  mixins: [useTexts],
  data() {
    return {
      page: 'register',
      form: [],
      model: {},
      rules: {},
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(form));
    this.model = JSON.parse(JSON.stringify(model));
    this.rules = JSON.parse(JSON.stringify(rules));
  },
  methods: {
    async handleSubmit(data) {
      const status = await Database.register(data);

      if (status !== 201)
        return Utilities.showMessage('error', 'register_error');
      
      this.$router.push({
        name: 'Success',
        params: { path: 'verification-email' },
      });
    },
  },
};
</script>
